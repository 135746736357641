.expart-featured-screen{
    background-image: url('../assets/featured-background.png');
}
.expart-featured-shadow{
    position: absolute;
    background-image: url('../assets/shadow.png');
    z-index: 10;
}
.expart-featured-screen, .expart-featured-shadow{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 100%;
    margin: 0 auto;
}

/* IMAGES */
.featured-images-section{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 5vw auto;
}
.main-featured-nft-container {
    position: relative;
}
.main-featured-nft-container .game-main-nft-box{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20vw;
    height: 70vh;
}
.main-featured-nft-container .game-main-nft{
    position: absolute;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 20vw;
    height: 50vh;
    object-fit: cover;
}
.featured-images-section .featured-nft-image-left img, .featured-images-section .featured-nft-image-right img{
    display: inline-block;
    width: 20vw;
    height: 20vw;
    object-fit: cover;
    margin-bottom: 30px;
    box-shadow:
    0 0 0 4px #2C2829,
    0 0 0 12px #A77B5B,
    0 0 0 16px #2C2828,
    0 0 0 20px #D3A068,
    0 0 0 24px #2C2827;
}
.featured-images-section p{
    margin-top: 25px;
    padding: 10px;
    width: 20vw;
    font-size: 20px;
    border-bottom: 5px solid #D3A068;
    border-right: 5px solid #D3A068;
    background-color: #EDE19E;
    box-shadow: 0 0 0 5px #2C2829;
}

.featured-nft-image-mid p{
    position: absolute;
    margin-top: 50vh;
    left: 50%;
    transform: translateX(-50%);
    width: 40vw;
    height: 25vh;
}