.result-score-page{
    background-color: #8C4141;
    border-bottom: 30px solid #6E3232;
    min-height: 100vh;
}

.result-score-main{
    padding: 30px 0;
}

.result-score-main .score-at-top{
    font-size: 60px;
    text-align: center;
    color: #51250E;
    background-color: #D3A068;
    padding: 30px 0;
    border-top: 5px solid #A84D4D;
    border-bottom: 5px solid #A84D4D;
}

.result-score-main .raffle-container{
    width: 70%;
    margin: 50px auto;
    box-shadow:
    0 0 0 5px #2C2829,
    0 0 0 10px #A77B5B,
    0 0 0 15px #2C2828,
    0 0 0 25px #D3A068,
    0 0 0 30px #2C2827;
    background-color: #E5CEB4;
    padding: 30px;
    display: flex;
    justify-content: space-between;
}

.result-score-main .raffle-container .raffle-texts{
    font-size: 20px;
    font-weight: 600;
    word-wrap: break-word;
}

.result-score-main .raffle-container .raffle-texts .score-box-username{
    word-break: break-all;
}

.result-score-main .raffle-container .raffle-image{
    box-shadow:
    0 0 0 5px #2C2829,
    0 0 0 10px #A77B5B,
    0 0 0 15px #2C2828,
    0 0 0 22px #D3A068,
    0 0 0 26px #2C2827;
    margin: 30px;
}
.result-score-main .raffle-container .raffle-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.result-score-main .share-box{
   border-bottom: 5px solid #D3A068;
   border-right: 5px solid #D3A068;
   background-color: #EDE19E;
   box-shadow: 0 0 0 5px #2C2829;
   width: 40%;
   margin: 40px auto;
   padding: 5px;
   font-size: 20px;
   font-weight: 600;
   display: flex;
   justify-content: space-around;
   align-self: center;
}
.result-score-main .share-box a{
    height: 15px;
}
.result-score-main .share-box i{
    transform: scale(2);
}

.temp{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 300px);
}

@media only screen and (max-width: 991px) {
    .result-score-main .raffle-container .raffle-texts form input{
        width: 100%;
        font-size: 13px;
        margin-bottom: 20px;
    }
    .result-score-main .raffle-container .raffle-image{
        box-shadow:
        0 0 0 3px #2C2829,
        0 0 0 6px #A77B5B,
        0 0 0 9px #2C2828,
        0 0 0 13px #D3A068,
        0 0 0 16px #2C2827;
    }
}

@media only screen and (max-width: 767px) {
    .result-score-main{
        padding-top: 70px;
    }
    .result-score-main .score-at-top{
        font-size: 30px;
        padding: 20px 0;
    }
    .result-score-main .raffle-container{
        width: 85%;
        margin: 50px auto;
        box-shadow:
        0 0 0 3px #2C2829,
        0 0 0 6px #A77B5B,
        0 0 0 9px #2C2828,
        0 0 0 15px #D3A068,
        0 0 0 18px #2C2827;
        padding: 10px;
        flex-wrap: wrap;
    }
    .result-score-main .share-box{
        width: 90%;
        flex-wrap: wrap;
    }
}

