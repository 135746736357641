.bounce-image-box{
    border: 6px solid white;
    overflow: hidden;
    position: relative;
}
.bounce-in-bottom{
    animation-name: bounceInBottom;
    animation-delay: 0.8s;
    animation-duration: 1s;
    animation-fill-mode: both;
}
@keyframes bounceInBottom {
    0% { transform: translate3d(0, 0, 0) }
    20% { transform: translate3d(0, -100px, 0) }
    21% { transform: translate3d(0, 100px, 0) }
    50% { transform: translate3d(0, 50px, 0) }
    73% { transform: translate3d(0, 12px, 0) }
    88% { transform: translate3d(0, -8px, 0) }
}