.expart-maingame-screen{
   position: relative;
}

.expart-maingame-screen .expart-maingame-background{
    background-color: #000;
    position: absolute;
    width: 100%;
    z-index: -2;
}
.expart-maingame-screen .expart-maingame-background-image{
    height: 100vh;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.expart-maingame-screen .game-components{
    /* width: 80%; */
    margin: 0 auto;
    padding-top: 50px;
}
.expart-maingame-screen .game-components .game-top-row{
    padding: 20px 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.expart-maingame-screen .game-components .game-top-row .dollars img{
    width: 40px;
}
.expart-maingame-screen .game-components .game-image-row{
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
}
.expart-maingame-screen .game-components .game-image-row .nft-image-container{
    text-align: center;
}
.expart-maingame-screen .game-components .game-image-row .nft-image-container img.game-main-nft{
    width: 35vh;
    height: 35vh;
    object-fit: cover;
    margin-bottom: 30px;
    box-shadow:
    0 0 0 4px #2C2829,
    0 0 0 12px #A77B5B,
    0 0 0 16px #2C2828,
    0 0 0 20px #D3A068,
    0 0 0 24px #2C2827;
}

.expart-maingame-screen .game-components .game-image-row .nft-image-container .game-nft-bottom-image{
    width: 100px;
}
.game-result-next-button-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.game-result-next-button{
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.288);
    width: 300px;
    margin: 0 auto;
}
.game-result-next-button .game-next-button{
    margin-bottom: 10px;
}
.clock-image-container{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: -1;
}
.clock-image-container img{
    max-width: 75%;
    max-height: 60vh;
    position: absolute;
    bottom: 15vh;
    transform: translateX(-50%);
}
.trial-game-info{
    position: absolute;
    /* top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 50%; */
    top: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.678);
    z-index: 1;
}

.trial-game-info h1{
    font-size: 32px;
    line-height: 40px;
    color: yellow;
    background-color: #D3A068;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
}

.trial-game-info .trial-game-info-form{
    position: absolute;
    bottom: 10px;
}
.for-shadow-highlight{
    position: relative;
    z-index: 2;
}
.for-shadow-highlight:after{
    background-color: #ebdcbec9;
    position: absolute;
    width: 35vh;
    height: 60vh;
    top: -25vh;
    left: 0;
    content: '';
    border-radius: 70%;
    /* clip-path: polygon(40% 0%, 54% 0%, 95% 63%, 100% 69%, 100% 75%, 60% 100%, 40% 100%, 0% 69%, 5% 63%); */
    clip-path: polygon(43% 0%, 58% 0%, 100% 67%, 100% 75%, 100% 80%, 61% 100%, 50% 100%, 39% 100%, 0% 81%, 0% 73%, 0% 66%);
    transform: scale(1.1);
    filter: blur(1rem);
}

.showhammer{
    cursor: url(../assets/hammer2.gif), auto;
    animation: cursor 1s linear infinite;
    }
    @keyframes cursor{
        0% { 
            cursor: url(../assets/hammer2.gif), auto;
        }
        50% { 
            cursor: url(../assets/hammer1.gif), auto;
        }
        100% { 
            cursor: url(../assets/hammer2.gif), auto;
        }
    }

@media only screen and (max-width: 1200px) {
    .trial-game-info h1{
        font-size: 22px;
        line-height: 28px;
        padding: 5px;
    }
}

@media only screen and (max-width: 991px) {
    .for-shadow-highlight{
        z-index: auto;
    }
    .expart-maingame-screen .game-components{
        padding-top: 10px;
    }
    .trial-how-to-play{
        font-size: 18px;
    }
    .trial-timer{
        font-size: 12px;
    }
    .expart-maingame-screen .game-components .game-image-row{
        padding-top: 20px;
    }
    .expart-maingame-screen .game-components .game-image-row .nft-image-container img.game-main-nft{
        box-shadow:
        0 0 0 2px #2C2829,
        0 0 0 6px #A77B5B,
        0 0 0 8px #2C2828,
        0 0 0 10px #D3A068,
        0 0 0 12px #2C2827;
    }
    .expart-maingame-screen .game-components .game-image-row .nft-image-container .game-nft-bottom-image{
        width: 60px;
    }


    /* lANDSCAPE VIEW - This CSS with .landscapeview was used for auto rotating screen for game page for mobile phones using css rotate*/
    
    /* .landscapeview  .expart-maingame-screen .expart-maingame-background-image{
        height: 100vw;
        width: 100%;
    }
    .expart-maingame-screen .game-components .game-top-row{
        padding: 20px 100px 0;
        justify-content: space-around;
    }
    .landscapeview .expart-maingame-screen .game-components .game-image-row .nft-image-container img.game-main-nft{
        width: 20vh;
        height: 20vh;
    } 
        
    .landscapeview .clock-image-container{
        width: 100%;
        height: 30vh;
        top: 220px;
    }
    .landscapeview .clock-image-container img{
        max-width: 75%;
        max-height: 30vh;
    } */
}