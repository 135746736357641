.expart-introduction-mainbox{
    background-color: #655561;
    position: relative;
}

/* Main Background Image */
.expart-introduction-mainbox img.expart-introduction-background{
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    margin: 0 auto;
    height: calc(100vh);
}

/* Typewriter Text */
.expart-introduction-mainbox .typewriter-effect-box{
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 27px;
    -webkit-text-stroke: 1px black;
    width: 80%;
}

.expart-introduction-mainbox .next{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 230px; */
}

.expart-introduction-mainbox .rules{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400px; */
}
/* .expart-introduction-mainbox .next:hover{
    width: 250px;
}
.expart-introduction-mainbox .rules:hover{
    width: 430px;
} */

/* Logo Image */
.expart-introduction-mainbox img.expartLogo3{
    width: 250px;
    position: absolute;
    left: 20%;
    bottom: 0;
    animation: appearFromBottom 3s;
}
@keyframes appearFromBottom {
    from {bottom:-80px}
    to {bottom: 0}
}

/* RESPONSIVENESS */

@media only screen and (max-width: 991px) {
    .expart-introduction-mainbox img.expart-introduction-background{
        max-width: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width: 767px) {
    .expart-introduction-mainbox .typewriter-effect-box{
        width: 95%;
        font-size: 18px;
    }
    .expart-introduction-mainbox .next{
        top: 40%;
    }
    .expart-introduction-mainbox .rules{
        bottom: 200px;
        width: 300px;
    }
}