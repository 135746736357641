.game-warning-container{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.904);
}
.warning-black-background{
    min-height: calc(100vh - 200px); 
}

.warning-black-background h1{
    margin-top: 130px;
    color: yellow;
    background-color: #D3A068;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0;
}
.warning-black-background h4.info{
    margin-top: 10px;
    color: yellow;
    text-align: center;
}

.warning-black-background img{
    display: block;
    margin: 50px auto;
    width: 30%;
}

.warning-black-background .warning-okay-button{
    text-align: center;
}


.metamask-unavailability-mobile-dialog{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40%;
    max-width: 40%;
    z-index: 98;
    top: 100px;
    background-color: white;
    text-align: center;
    border: 5px solid black;
    padding: 30px;
}

.metamask-unavailability-mobile-dialog .mobile-metamask-dialog-title{
    font-size: 20px;
}

@media only screen and (max-width: 991px) {
    .warning-black-background h1{
        margin-top: 50px;
        font-size: 24px;
        padding: 10px 0;
    }
    .warning-black-background img{
        width: 200px;
    }
    .metamask-unavailability-mobile-dialog{
        width: 100%;
        max-width: 100%;
    }
}