.profile-page-mainbox{
    background-image: url('../../assets/highscore-screen-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: calc(100vh);
    margin: 0 auto;
    padding-top: 40px;
    color: #3D2B0D;
}

.profile-page-upper-part{
    display: flex;
    margin-bottom: 20px;
}

.profile-box{
    width: 70%;
    margin: 0 10px 0 20px;
}
.profile-box h1{
    font-size: 60px;
    font-weight: 700;
    background-color: #E0D0B4;
    text-align: center;
    padding: 30px 0;
    height: 95%;
}

.username-box{
    /* background-color: #E0D0B4; */
    width: 30%;
    margin: 0 20px 0 10px;
}

.profile-image-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 95%;
}

.profile-image-box img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 5px solid #AB8F5E;
    margin: 10px 20px;
}



.profile-page-lower-part{
    display: flex;
}

.profile-page-completed-games{
    width: 70%;
    margin: 0 10px 0 20px;  
}

.profile-lower-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.profile-lower-header h2{
    font-size: 32px;
    font-weight: 700;
    color: #3D2B0D;
}
.profile-lower-header img{
    max-width: 50px;
}

.profile-score-table-header{
    display: flex;
    background-color: #AB8F5E;
    padding: 12px 0;
    justify-content: space-around;
    font-weight: 700;
}
.profile-score-table-header span{
    flex: 1;
    text-align: center;
}

.expart-buck-and-toggler{
    position: relative;
    top: -5px;
}
.expart-buck-and-toggler img{
    position: relative;
    left: 30px;
}

.profile-page-stats{
    width: 30%;
    margin: 0 20px 0 10px;  
}
.profile-stats-box{
    padding: 0 20px 20px;
    font-weight: 700;
}



/* BOX WITH CORNER DESIGN */
.design-corner-box{
    position: relative;
    background:#AB8F5E;
}
.design-corner-box:after {
    content: "";
    background:#AB8F5E;
    position: absolute;
    margin: 0 auto;
    bottom: -3px;
    left: 0.5%;
    height: 5px;
    width: 99%;
}
.design-corner-box-child:before {
    content: "";
    background:#E0D0B4;
    position: absolute;
    margin: 0 auto;
    top: -3px;
    left: 0.5%;
    height: 5px;
    width: 99%;
}
.design-corner-box-child:after {
    content: "";
    background:#E0D0B4;
    position: absolute;
    margin: 0 auto;
    bottom:5px;
    left: 0.5%;
    height: 5px;
    width: 99%;
}
.design-corner-box-child{
    background-color: #E0D0B4;
    margin-bottom: 0;
    /* height: 99%; */
}


@media only screen and (max-width: 991px) {
    .profile-box h1{
        font-size: 40px;
        line-height: 95%;
    }  
    .profile-image-box{
        flex-wrap: wrap;
    }
    .profile-image-box p{
       padding: 0 20px;
       word-break: break-all;
    }
}


@media only screen and (max-width: 767px) {
    .profile-page-mainbox{
        padding-top: 70px;
    }
    .profile-page-upper-part{
        flex-wrap: wrap;
    }
    .profile-box h1{
        font-size: 25px;
    }
    .profile-box{
        width: 100%;
        margin-bottom: 20px;
        margin-left: 10px;
    }
    .username-box{
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
    }

    .profile-page-lower-part{
       flex-wrap: wrap;
    }
    .profile-page-completed-games{
        width: 100%;
        margin: 0 10px 30px 10px;  
    }
    .profile-page-stats{
        width: 100%;
        margin: 0 10px 0 10px;  
    }

    .profile-lower-header h2{
        font-size: 20px;
    }
    .profile-score-table-header span{
        font-size: 14px
    }
    .profile-stats-box h5{
        font-size: 16px;
    }
    .profile-stats-box  p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .profile-score-table-header span{
        font-size: 10px
    }
}