@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.maskLeft, .maskRight{
  opacity:50%;
}

.actionButton{
  height:100px;
  width:100px;
  background:lightgray;
  cursor: pointer;
  position: fixed;
  right:20px;
  bottom:20px;
  border-radius: 50px;
  text-align:center;
  padding-top:35px;
}
.actionButton:hover{
  opacity:80%;
}

.tutorialLocation{
  position:fixed;
  top:20px;
  right:20px;
}