.expart-shop-page{
    background-color: #8C4141;
    border-bottom: 30px solid #6E3232;
    min-height: 100vh;
    padding: 30px 0;
}

.shop-title-at-top{
    font-size: 60px;
    text-align: center;
    color: #51250E;
    background-color: #D3A068;
    padding: 30px 0;
    border-top: 5px solid #A84D4D;
    border-bottom: 5px solid #A84D4D;
}

.shop-box{
    width: 80%;
    margin: 50px auto;
    box-shadow:
    0 0 0 5px #2C2829,
    0 0 0 10px #A77B5B,
    0 0 0 15px #2C2828,
    0 0 0 25px #D3A068,
    0 0 0 30px #2C2827;
    background-color: #E5CEB4;
    padding: 30px;
}

.buy-lives-box h1{
    margin-bottom: 20px;
}

.alert-error {
    background: pink;
    margin: 20px;
}

.user-id-shop{
    word-break: break-all;
}

.extra-life-box{
    border: 5px solid #51250E;
    margin: 10px 0 30px;
    padding: 20px 10px;
}

.extra-life-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.life-card-shop{
    margin: 25px;
    padding: 10px;
    background-color: #996951;
    text-align: center;
    box-shadow:
        0 0 0 3px #2C2829,
        0 0 0 6px #A77B5B,
        0 0 0 9px #2C2828,
        0 0 0 13px #D3A068,
        0 0 0 16px #2C2827;
}

.life-card-shop-extra{
    color: #D0CD68;
    font-size: 18px;
    margin-bottom: 0px;
}
.life-card-shop-life{
    color: #e9e56e;
    font-size: 22px;
    margin-bottom: 5px;
}

.life-card-shop  img{
    margin: 0 10px 10px;
}
.life-card-shop .nes-shop-apply-now{
    font-size: 10px;
}

@media only screen and (max-width: 767px) {
    .expart-shop-page{
        padding-top: 70px;
    }
    .expart-shop-page .shop-title-at-top{
        font-size: 30px;
        padding: 20px 0;
    }
    .expart-shop-page .shop-box{
        width: 85%;
        margin: 50px auto;
        box-shadow:
        0 0 0 3px #2C2829,
        0 0 0 6px #A77B5B,
        0 0 0 9px #2C2828,
        0 0 0 15px #D3A068,
        0 0 0 18px #2C2827;
        padding: 10px;
        flex-wrap: wrap;
    }
}