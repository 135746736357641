.score-table-data{
    border-bottom: 4px solid #AB8F5E;
}
.score-table-data-toggler{
    display: flex;
    padding: 20px 0;
    font-weight: 700;

}
.score-table-data-toggler div{
    flex: 1;
    text-align: center;
}

.score-data-inner-table{
    padding: 5px;
    background-color: #AB8F5E;
}

.score-data-inner-table-heading{
    display: flex;
    justify-content: space-around;
}
.score-data-inner-table-heading span{
    flex: 1;
    text-align: center;
    font-size: 30px;
    line-height: 50px;
    background-color: #E0D0B4;
    margin: 5px 5px 5px;
}
.score-round-details-roundno{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.score-round-details-dollars{
    flex: 1;
}
.score-round-details-dollars .dollar-color-box{
    content: '';
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 4px solid #3D2B0D;
}
.dollar-color-box-pink{
    background-color: #D77BBA;
}
.dollar-color-box-red{
    background-color: #8F110A;
}
.game-score-dollar{
    font-size: 16px;
}

.score-data-inner-table-row{
    display: flex;
    justify-content: space-around;
}

.score-round-details{
    background-color: #E0D0B4;
    margin: 0 5px 5px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    padding: 10px 0 5px;
}

.score-round-time{
    flex: 1;
    background-color: #E0D0B4;
    margin: 0 5px 5px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media only screen and (max-width: 767px) {
    .score-table-data-toggler{
        font-size: 15px;
    }
    .score-data-inner-table-heading span{
        font-size: 18px;
    }   
    .score-round-details-roundno{
        font-size: 13px;
    }
    .score-round-details-dollars span{
        font-size: 12px;
    }
    .score-round-time{
        font-size: 13px;
    }
}
@media only screen and (max-width: 400px) {
    .score-table-data-toggler{
        font-size: 10px;
    }
}