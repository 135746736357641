.text-page-top-margin{
    margin-top: 40px;
}



@media only screen and (max-width: 767px) {
    .text-page-top-margin{
        margin-top: 80px;
    }    
}