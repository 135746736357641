.menu-container{
    position: absolute;
    top: 10px;
    z-index: 99;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-container .left-directory{
    width: 250px;
}
.menu-container .left-directory select{
    background-color: #00000018;
    color: #ffffff;
}
.menu-container .left-directory.is-dark select{
    background-color: #3f3b3b73;
}
.menu-container .right-profile{
    text-align: center;
}

.menu-container .right-profile span{
    color: #ffffff;
    padding: 10px;
    background: #00000063;
    display: inline-block;
}
.menu-container .right-profile span:hover{
    background: #000000a1;
}
.metamask-unsupport-dialog{
    text-align: center;
    z-index: 98;
    margin-top: 30vh;
}

.metamask-unavailability-dialog{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40%;
    max-width: 40%;
    z-index: 98;
    margin-top: 30vh;
    background-color: white;
    text-align: center;
    border: 5px solid black;
    padding: 30px;
}

.metamask-unavailability-dialog .menu-dialog-title{
    font-size: 20px;
}

@media only screen and (max-width: 991px) {
    .menu-container{
        padding: 0 15px;
        top: 15px;
    }
    .menu-container .left-directory{
        width: 150px;
    }
    .menu-container .left-directory select{
        font-size: 11px;
        padding-left: 4px;
        padding-right: 4px;
    }
    .menu-container .right-profile span{
        font-size: 11px;
    }
    .metamask-unavailability-dialog{
        width: 100%;
        max-width: 100%;
    }
}