.level-up-popup-container{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: hidden;
}
.level-up-popup-background{
    min-height: calc(100vh - 200px); 
}

.level-up-popup-background h1{
    margin-top: 30px;
    color: yellow;
    background-color: #D3A068;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0;
}

.level-up-popup-background .warning-okay-button{
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .level-up-popup-background h1{
        margin-top: 80px;
    }
}