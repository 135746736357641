.highscore-screen-mainbox{
    background-image: url('../../assets/highscore-screen-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: calc(100vh);
    margin: 0 auto;
}

.highscore-innerbox{
    padding: 20px;
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
}

.highscore-innerboxes{
    flex-grow: 1;
    text-align: center;
}

.highscore-innerboxes .highscore-time-frame{
    font-size: 25px;
    text-transform: uppercase;
    color: #80F74D;
}
.highscore-innerboxes .highscore-heading{
    font-size: 30px;
    text-transform: uppercase;
    color: #F7D84D;
}

.highscore-innerboxes .highscore-table{
    border: 10px solid white;
    margin: 40px 10px;
    color: #EDDEDA;
}

.highscore-innerboxes .highscore-table td, th{
    color: #EDDEDA;
    border: none;
}

.highscore-your-scores-section{
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    color: #EDDEDA;
}

.rank-image-box-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.highscore-table{
    max-height: 50vh;
    overflow: scroll;
    overflow-x: hidden;
}
.highscore-table .table{
    /* display: block;
    overflow-x: auto;
    white-space: nowrap; */
   word-break: break-all;
}

.table-row-red{
    background: #db4437;
}

@media only screen and (max-width: 991px) {
    .highscore-screen-mainbox{
        background-repeat: repeat;
       background-size: contain;
       background-position: center;
    }
}

@media only screen and (max-width: 767px) {
    .highscore-innerbox{
        padding-top: 80px;
    }
    .highscore-screen-mainbox{
       background-repeat: repeat;
       background-size: contain;
       background-position: center;
    }
}