.enter-expart-mainbox{
    background-color: #655561;
    position: relative;
}

/* Main Background Image */
.enter-expart-mainbox img.expart-enter-background{
    padding-top: 10px;
    padding-bottom: 5px;
    display: block;
    margin: 0 auto;
    height: calc(100vh);
}

/* Typewriter Text */
.enter-expart-mainbox .typewriter-effect-box{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 30px;
    -webkit-text-stroke: 1px black;
}

.enter-expart-mainbox .next{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 230px; */
}

.enter-expart-mainbox .rules{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400px; */
}
/* .enter-expart-mainbox .next:hover{
    width: 250px;
}
.enter-expart-mainbox .rules:hover{
    width: 430px;
} */

.button-new-styles{
    font-size: 24px;
}

@media only screen and (max-width: 1200px) {
    .enter-expart-mainbox .typewriter-effect-box{
        font-size: 24px;
    }
}

@media only screen and (max-width: 991px) {
    .enter-expart-mainbox img.expart-enter-background{
        max-width: 100%;
        object-fit: cover;
    }
}

@media only screen and (max-width: 767px) {
    .enter-expart-mainbox .typewriter-effect-box{
        top: 25%;
        width: 80%;
    }
    .enter-expart-mainbox .rules{
        width: 300px;
        bottom: 50px;
    }
}